.ContainerSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Cosmonaut {
  width: 166px;
}

.Thanks {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0f7e7;
  width: 100%;
  margin-top: 37.6px;
  max-width: 683px;
}

.ThanksContainer {
  display: flex;
  align-items: center;
  margin: 30px 45px;
}

.Center {
  justify-content: center !important;
}

.MapContainer {
  display: flex;
  outline: none;
  cursor: pointer;
  max-width: 600px;
}

.MapImagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.SeeMaps {
  display: flex;
  cursor: pointer;
  outline: none;
  margin-top: 33px;
}

.SeeMaps > p {
  margin: 0;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  text-decoration: underline
}

.MapImage {
  width: 100%;
  border-radius: 10px;
  max-width: 350px;
  height: intrinsic;
}

.IconMap {
  margin-right: 12.3px;
}

.ThanksText {
  margin: 0;
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Ok {
  width: 64px;
  margin-right: 26px;
}

.CenterContainer {
  display: flex;
  flex-direction: column;
  padding: 28px;
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  border: solid 1.3px #003fff;
  background-color: #ffffff;
  outline: none;
}

.Center, .Dates {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Item {
  display: flex;
}

.Icon {
  width: 20.8px;
}

.Address {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Name {
  margin:0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.ItemSecond {
  display: flex;
  margin-top: 24px;
}

.ContainerLinkCalendar {
  display: flex;
  margin-top: 37.8px;
}

.ImageCalendar {
  width: 9.8px;
  margin-right: 8.4px;
}

.LinkCalendar {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ResumeLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  align-items: center;
  margin-top: 29px;
}

.Recommendation {
  display: flex;
  border-radius: 10px;
  background-color: #fceed2;
  margin-top: 43px;
  max-width: 350px;
}

.ContainerRec {
  display: flex;
  flex-direction: column;
  margin: 16px 25px;
}

.TitleRecommendation {
  font-family: ModernEraBold;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 0;
}

.ItemRec {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 16px 0 0;
}

.NewAppointment {
  border-radius: 10px;
  border: solid 3px #003fff;
  background-color: #ffffff;
  padding: 17px 50px;
  font-family: ModernEraBold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #003fff;
  margin-top: 24.5px;
  outline: none;
  cursor: pointer;
}

@media(max-width: 350px){
  .CenterContainer {
    max-width: 224px;
  }

  .Recommendation {
    max-width: 280px;
  }


  .ThanksContainer {
    margin: 30px 30px;
  }

  .MapImage {
    max-width: 280px;
  }
}

.TextFooter {
  margin: 36px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

@media(min-width: 700px){
  .Ok {
    width: 86px;
    margin-right: 50px;
  }

  .ResumeLink {
    margin-top: 34px;
  }

  .Section {
    flex-direction: row;
    max-width: 735px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Recommendation {
    margin-top: 32px;
  }

  .ThanksContainer {
    margin: 33px 49px;
  }

  .MapImage {
    max-width: 100%;
  }
}

.ButtonBlue {
  margin-top: 30px;
  background-color: #003fff!important;
  width: 80%;
  max-width: 309px;
}

.ButtonBlue:hover {
  background-color: #023ae0!important;
}

.textProtected {
  margin-top: 24px;
  color: #003FFF!important;
  width: 80%;
  max-width: 309px;
}

.Title{
  font-size: 20px;
  font-family: 'Poppins Medium';
  color: #212121;
  margin-top: 32px;
  margin-bottom: 16px;
  line-height: 28px;
  text-align: center;
}
.Description{
  text-align: center;
  font-family: 'Inter Regular';
  font-size: 14px;
  line-height: 20px;
  max-width: 607px;
  letter-spacing: 0.0025em;
  color: #757575;
  margin-top: 0;
  margin-bottom: 0;
}